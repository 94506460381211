<template id="app">
  <section class="">
    <div class="columns">
      <div class="column is-4 is-offset-4">
          <div align="center">
            <router-link to="/">
              <img style="width:100%;" src="@/assets/logo.png"/>
            </router-link>
        </div>
        <router-view />
      </div>
    </div>
  </section>
  <div class="images">
    <div v-for="i in images">
      <img :src="'0'+i+'.png'" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    images() {
      return shuffle([1, 2, 3, 4]);
    },
  },
};
</script>

<style>
</style>

<style lang="scss">
html {
  height: 100%;
}
body {
  min-height: 100vh;
}

.images {
  z-index: -1;
  position: fixed;
  inset: 0;
  display: flex;
  background: #fee100;

  div {
    flex: 1;
    height: 50vh;
    margin-top: 50vh;
    text-align: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>