import { createStore } from 'vuex'

export default createStore({
  state: {
    loading: false,
    joueurs: [],
    groupes: [],
    relations: [],
    groupesJoueurs: [],
    key:false
  },
  getters: {
    joueurs(state) {
      return state.joueurs;
    },
    loading(state) {
      return state.loading;
    },
    joueursAlpha(state) {
      return state.joueurs.sort((j1, j2) => j1.nom > j2.nom);
    },
    groupesJoueur(state) {
      return (id) => {
        for (const gj of state.groupesJoueurs) {
          if (gj.joueur_id == id) {
            if (gj.groupes_id) {
              let ids = gj.groupes_id.split(",");
              let noms = gj.groupes.split(", ");
              let groupes = [];
              ids.forEach((id, index) => {
                groupes.push({ id: id, nom: noms[index] });
              });
              return groupes;
            }
          }
        }
      }
    },
  },
  mutations: {
    setKey(state, data) {
      state.key = data;
      setCookie("key", data)
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setJoueurs(state, data) {
      state.joueurs = data;
    },
    setGroupes(state, data) {
      state.groupes = data;
    },
    setGroupesJoueurs(state, data) {
      state.groupesJoueurs = data;
    },
  },
  actions: {
    chargerJoueurs({ commit, getters }) {
      commit('setLoading', true);
      supabase.rpc("get_groupes").then((response) => {
        commit('setGroupesJoueurs', response.data);

        let r;
        r = supabase.from("joueurs").select("*").order('nom')
        //.limit(20);


        r.then((response => {
          commit('setLoading', false);

          let joueurs = [];
          for (let joueur of response.data) {
            joueur.groupes = getters.groupesJoueur(joueur.id);
            joueurs.push(joueurs)
          }
          commit('setJoueurs', response.data)
        }))
      });
    },

  },
  modules: {
  }
})
