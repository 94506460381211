import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createClient } from '@supabase/supabase-js'

import mitt from 'mitt';
const bus = mitt();

export const supabase = createClient(process.env.VUE_APP_SUPABASE_URL, process.env.VUE_APP_SUPABASE_ANON_KEY)
window.supabase = supabase;
const app = createApp(App).use(store).use(router)

app.config.globalProperties.bus = bus;


app.mount('#app')